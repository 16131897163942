import React, { useState } from "react";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={styles.hamburgerMenu}>
      <button style={styles.hamburgerButton} onClick={toggleMenu}>
        ☰
      </button>
      {isOpen && (
        <div style={styles.menuDropdown}>
          <a style={styles.menuItem} href="/">
            Home
          </a>
          <a style={styles.menuItem} href="/upload">
            Upload
          </a>
          <a style={styles.menuItem} href="/confirm_email">
            Confirm Email
          </a>
          <a
            style={styles.menuItem}
            href="/photoshoot/615391a4-fad5-4d49-8cec-1cf1f048205c"
          >
            Photo Shoot
          </a>
          <a style={styles.menuItem} href="/customize">
            Customize
          </a>
          <a style={styles.menuItem} href="/about">
            About
          </a>
          <a style={styles.menuItem} href="/contact">
            Contact
          </a>
          <a style={styles.menuItem} href="/redirect">
            Redirect
          </a>
          <a style={styles.menuItem} href="*">
            PageNotFound
          </a>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;

const styles = {
  hamburgerMenu: {
    position: "absolute",
    top: "20px",
    right: "20px",
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
  hamburgerButton: {
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
  },
  menuDropdown: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "40px",
    right: "0",
    backgroundColor: "#f9f9f9",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px",
    zIndex: 999,
  },
  menuItem: {
    textDecoration: "none",
    color: "#333",
    padding: "5px",
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
  menuItemHover: {
    backgroundColor: "#ddd",
  },
};
