import styled from "styled-components";

export const SquareButton = styled.button`
  background-color: ${(props) => (props.disabled ? "#CCCCCC" : "#8300E9")};
  color: ${(props) => (props.disabled ? "#666666" : "white")};
  border: none;
  border-radius: 0;
  padding: 14px 30px; /* Adjust padding to make it more square-like */
  font-size: 18px;
  font-weight: 700;
  max-width: 268px;
  width: 100%;
  font-family: inherit;
  align-self: inherit;
  cursor: ${(props) =>
    props.disabled
      ? "not-allowed"
      : "pointer"}; /* Use not-allowed cursor when disabled */
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.disabled ? "#CCCCCC" : "#A860E0")};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Focus ring */
  }

  &:active {
    background-color: ${(props) => (props.disabled ? "#CCCCCC" : "#A860E0")};
  }
`;

export const SquareButtonTransparent = styled.button`
  background-color: ${(props) => (props.disabled ? "#CCCCCC00" : "#007bff00")};
  color: ${(props) => (props.disabled ? "#666666" : "white")};
  border: 1px solid white;
  border-radius: 0;
  padding: 14px 30px; /* Adjust padding to make it more square-like */
  font-size: 18px;
  font-weight: 700;
  max-width: 268px;
  width: 100%;
  font-family: inherit;
  align-self: inherit;
  cursor: ${(props) =>
    props.disabled
      ? "not-allowed"
      : "pointer"}; /* Use not-allowed cursor when disabled */
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.disabled ? "#CCCCCC" : "rgba(255, 255, 255, 0.35)")};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Focus ring */
  }

  &:active {
    background-color: ${(props) => (props.disabled ? "#CCCCCC" : "#858585")};
  }
`;
