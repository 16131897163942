import React from "react";
import PhotoGridItem from "./PhotoGridItem";
import "./PhotoGridView.css";

const PhotoGridView = ({ className, items, onItemClick, selectedItems }) => {
  return (
    <div className={`${className} gridContainer`}>
      {items.map((item, index) => (
        <PhotoGridItem
          key={index}
          imageSrc={item.thumbnail}
          selected={selectedItems.includes(item)}
          onClick={() => onItemClick(item)}
        />
      ))}
    </div>
  );
};

export default PhotoGridView;