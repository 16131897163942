import React, { useState } from "react";

import circleIcon from "../../../assets/icons/circle.svg";
import circleCheckedIcon from "../../../assets/icons/check_circle.svg";
import "./PhotoGridItem.css";

const PhotoGridItem = ({ imageSrc, selected, onClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const disableContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className="gridItem" onClick={onClick}>
      <img
        src={imageSrc}
        alt="Item"
        className="image"
        onLoad={handleImageLoad}
        onLoadStart={handleImageLoad}
        onContextMenu={disableContextMenu}
      />
      {imageLoaded && (
        <div className="gridItemOverlay">
          {selected ? (
            <img
              src={circleCheckedIcon}
              alt="Item"
              className="selectedIcon"
              onContextMenu={disableContextMenu}
            />
          ) : (
            <img
              src={circleIcon}
              alt="Item"
              className="selectedIcon"
              onContextMenu={disableContextMenu}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PhotoGridItem;
