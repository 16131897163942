export const partnerId = "xomgpop";

export const heicExtensions = ["heic", "HEIC", "heif", "HEIF"];

export const uploadErrorMessages = {
  duplicateFile: {
    text: "⛔️ You've already uploaded this photo",
    color: "red",
  },
  fileFormat: {
    text: "⛔ Accepted file formats: JPG, PNG, HEIC",
    color: "red",
  },
  uploadFailed: {
    text: "⛔ Upload failed, please try again",
    color: "red",
  },
  maxUploads: {
    text: "👍 All set, you've already uploaded 10 photos",
    color: "green",
  },
};
