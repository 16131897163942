import React from "react";

const UploadPreview = ({ file, onRemove }) => {
  const overlayClass =
    file.uploadStatus === "uploading"
      ? "preview-overlay overlay-uploading"
      : file.uploadStatus === "failed"
      ? "preview-overlay overlay-failed"
      : "preview-overlay";

  const isHeifOrHeic = file.type === "image/heif" || file.type === "image/heic";

  return (
    <div className="previewThumb" key={file.name}>
      <div className={`previewTopGradientOverlay ${file.uploadStatus}`}>
        <div className="uploadStatusText">
          {file.uploadStatus === "completed" && ""}
          {file.uploadStatus === "uploading" && ""}
          {file.uploadStatus === "failed" && "Upload Failed"}
        </div>

        <button className="removeButton" onClick={() => onRemove(file)}>
        </button>
        {file.uploadStatus === "uploading" && (
          <div className="uploadingSpinner"></div>
        )}
      </div>

      {isHeifOrHeic ? null : (
        <img src={file.preview} className="previewImage" alt={file.name} />
      )}

      <div className={overlayClass} />
    </div>
  );
};

export default UploadPreview;
