import React from "react";

function SubmissionCompletePage() {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Your photos are being created...</h1>
      <p style={styles.p}>Thank you! Your photos will be created within 2 hours. You will receive an email with a link when they are ready.</p>
      <p style={styles.p}>Questions? Feedback? <a style={styles.link} href="mailto:xomgpop@joinhoneycomb.com">xomgpop@joinhoneycomb.com</a></p>
    </div>
  );
}

export default SubmissionCompletePage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    maxWidth: "450px",
    margin: "0 auto",
    padding: "0 1rem",
    textAlign: "left",
  },
  header: {
    fontSize: "30px",
    textAlign: "center",
  },
  p: {
    width: "100%",
  },
  link: {
    color: "#333",
  }
};