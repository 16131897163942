import React, { useEffect, useState } from "react";
import { /*useNavigate, */ useLocation } from "react-router-dom";

import plainTee from "../../../assets/images/plain-white-tee.webp";
import landingNumber1 from "../../../assets/images/landing_number_1.webp";
import landingNumber2 from "../../../assets/images/landing_number_2.webp";
import PhotoGridView from "../../common/PhotoGrid/PhotoGridView";
import rainbowSparkleLogo from "../../../assets/images/rainbow_sparkle_logo_small.png";
import rainbowSparkleLogoSmall from "../../../assets/images/rainbow_sparkle_logo_smaller.png";
import "./CustomizePage.css";

import { SquareButton } from "../../common/Buttons/Buttons";

function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(",")[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/png" });
}

async function watermarkImage(originalImage, watermarkImagePath) {
  document
    .getElementById("background-image")
    .setAttribute("crossOrigin", "anonymous");
  document
    .getElementById("background-image")
    .setAttribute("src", originalImage.restored_face);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const baseImage = document.getElementById("background-image");
  baseImage.onload = async () => {
    const canvasWidth = baseImage.width;
    const canvasHeight = baseImage.height;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight + 450;

    // initializing the canvas with the original image
    context.drawImage(baseImage, 0, 0, canvasWidth, canvasHeight);

    // loading the watermark image and adding it to the canvas
    const result = await fetch(watermarkImagePath);
    const blob = await result.blob();
    const image = await createImageBitmap(blob);
    context.drawImage(image, 0, 1450, image.width, image.height);

    document.getElementById("canvas-area").appendChild(canvas);
    document.getElementById("background-image").remove();
    const dataUrl = canvas.toDataURL("image/png");
    var blobData = dataURItoBlob(dataUrl);
    //TODO: upload blobData to S3 here!
    return blobData;
  };
}

async function updateTeeImage(originalImage, watermarkImagePath) {
  document
    .getElementById("t-background-image")
    .setAttribute("src", originalImage.thumbnail);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const baseImage = document.getElementById("t-background-image");
  baseImage.onload = async () => {
    const canvasWidth = baseImage.width;
    const canvasHeight = baseImage.height;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight + 60;

    // initializing the canvas with the original image
    context.drawImage(baseImage, 0, 0, canvasWidth, canvasHeight);

    // loading the watermark image and adding it to the canvas
    const result = await fetch(watermarkImagePath);
    const blob = await result.blob();
    const image = await createImageBitmap(blob);
    context.drawImage(image, 0, 180, image.width, image.height);

    document.getElementById("customizeImageWrapper").appendChild(canvas);
  };
}

const clearCanvas = () => {
  const canvases = document
    .getElementById("customizeImageWrapper")
    .querySelectorAll("canvas");
  if (canvases.length > 0) {
    canvases[0].remove();
  }
};

function CustomizePage() {
  // const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState(null);

  const location = useLocation();
  const { data: downloadedOutputs } = location.state || {};

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    handleItemClick(downloadedOutputs[0]);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadedOutputs]);

  const handleItemClick = (selectedItem) => {
    clearCanvas();
    setSelectedItem(selectedItem);
    updateTeeImage(selectedItem, rainbowSparkleLogoSmall);
  };

  const submitAndRedirect = async () => {
    setSubmitting(true);
    // TODO: create custom url with location of selectedItem[0]

    watermarkImage(selectedItem, rainbowSparkleLogo);

    // setTimeout(() => {
    //   navigate("/redirect");
    // }, 2000);
  };

  return (
    <div className="container">
      <h1>Print Your Custom Merch</h1>
      <div className="main">
        <div className="instructions">
          <div className="step">
            <img src={landingNumber1} alt="" className="stepNumber" />
            <p className="text">
              Select one of your favorite photos to print on your very own XOMG
              POP! live tour tee!
            </p>
          </div>
          <div id="customizeImageWrapper">
            <img id="t-background-image" alt="" />
            <img
              src={plainTee}
              alt="Your Custom Tee"
              className="customizeImage"
            />
          </div>
          <div className="step">
            <img src={landingNumber2} alt="" className="stepNumber" />
            <p className="text">
              Choose your Tshirt size and place your order.
            </p>
          </div>
        </div>
        <PhotoGridView
          className="customizePage"
          items={downloadedOutputs}
          onItemClick={handleItemClick}
          selectedItems={[selectedItem]}
        />
      </div>
      <SquareButton
        disabled={selectedItem === null || submitting}
        onClick={submitAndRedirect}
        className={"downloadCTA"}
      >
        CONTINUE
      </SquareButton>
      <div id="canvas-area">
        <img id="background-image" alt="" />
      </div>
      <div className="gradient"></div>
    </div>
  );
}

export default CustomizePage;
