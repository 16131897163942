import AWS from "aws-sdk";

// AWS.config.update({
//   region: "YOUR_AWS_REGION",
//   credentials: new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: "YOUR_COGNITO_IDENTITY_POOL_ID",
//   }),
// });

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: "us-west-2",
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "honeycomb-submissions" },
});

export default s3;
