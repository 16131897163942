import React, { useEffect } from "react";
import { SquareButton } from "../../common/Buttons/Buttons";

const redirectLink = "http://www.google.com";

function RedirectPage() {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = redirectLink;
    }, 7000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={styles.container}>
      <p>
        Great! We hope you love your photos. Next, choose your custom merch and check
        out.
      </p>
      <div style={styles.spinner}></div>
      <SquareButton disabled={true}>REDIRECTING...</SquareButton>
    </div>
  );
}

export default RedirectPage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.5rem",
    maxWidth: "320px",
    margin: "0 auto",
  },
  spinner: {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderLeftColor: "#333",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite",
    marginBottom: "20px",
  },
};
