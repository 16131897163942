import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SquareButton } from "../../common/Buttons/Buttons";
import { checkEmail } from "../../../utils/formHelpers";
import { partnerId } from "../../../utils/constants";

function ConfirmEmailPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [submitting, setSubmitting] = useState(false);
  // TODO: not hardcode...?
  const gender = "girl";
  const jobId = localStorage.getItem("jobId");
  const packId = "xomgpop";
  const packName = "XomgPop";
  const partner = partnerId;

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (submitting) return;
    setSubmitting(true);

    // DEBUG logging
    // console.log("Submitted jobId:", jobId);
    // console.log("Submitted partner:", partner);
    // console.log("Submitted email:", email);
    // console.log("Submitted gender:", gender);
    // console.log("Submitted packId:", packId);
    // console.log("Submitted packName:", packName);

    await createTransformPartnershipJob();

    setSubmitting(false);
    navigate("/complete");
  };

  const createTransformPartnershipJob = async () => {
    const query = `
        mutation {
            createTransformPartnershipJob(
                uuid: "${jobId}",
                packId: "${packId}",
                packName: "${packName}",
                email: "${email}",
                partner: "${partner}",
                gender: "${gender}"
            ) {
              createTransformPartnershipJob
            }
        }
    `;

    try {
      const result = await fetch("https://app.joinhoneycomb.com/graphql/", {
        // Updated URL here
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      });

      await result.json();
    } catch (error) {
      console.error("Error submitting the GraphQL mutation:", error);
    }
  };

  const isEmailValid = checkEmail(email);

  return (
    <div style={styles.container}>
      <h1>Last Step!</h1>
      <p>
        Please enter your email address. We will send your photos here when they
        are ready.
      </p>
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={handleInputChange}
        placeholder="Your email address"
        style={styles.emailInput}
        required
      />
      <SquareButton
        disabled={!isEmailValid || submitting}
        onClick={handleSubmit}
      >
        SUBMIT
      </SquareButton>
    </div>
  );
}

export default ConfirmEmailPage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    maxWidth: "347px",
    margin: "0 auto",
    padding: "0 1.5rem",
    textAlign: "left",
  },
  emailInput: {
    padding: "1.5rem",
    borderRadius: "1.25rem",
    fontSize: "16px",
    fontFamily: "inherit",
    border: "1px solid #858585",
    outline: "none",
    margin: "2rem auto 3rem",
    width: "100%",
    boxSizing: "border-box",
  },
};
