import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutPage from "./components/pages/AboutPage/AboutPage";
import ContactPage from "./components/pages/ContactPage/ContactPage";
import NotFoundPage from "./components/pages/NotFoundPage/NotFoundPage";
import PhotoShootPage from "./components/pages/PhotoShootPage/PhotoShootPage";
import NavBar from "./components/common/NavBar/NavBar";
import UploadPage from "./components/pages/UploadPage/UploadPage";
import ConfirmEmailPage from "./components/pages/ConfirmEmailPage/ConfirmEmailPage";
import LandingPage from "./components/pages/LandingPage/LandingPage";
import SubmissionCompletePage from "./components/pages/SubmissionCompletePage/SubmissionCompletePage";
import RedirectPage from "./components/pages/RedirectPage/RedirectPage";
import CustomizePage from "./components/pages/CustomizePage/CustomizePage";

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          {/* Main flow */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/confirm_email" element={<ConfirmEmailPage />} />
          <Route path="/complete" element={<SubmissionCompletePage />} />
          <Route path="/photoshoot/:id" element={<PhotoShootPage />} />
          <Route path="/redirect" element={<RedirectPage />} />
          <Route path="/customize" element={<CustomizePage />} />

          {/* Additional pages */}
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
