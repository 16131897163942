import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import xomgpopHeaderLogo from "../../../assets/brand/xomgpop_logo_desktop.webp";
import "./NavBar.css";

import HamburgerMenu from "./HamburgerMenu";

function NavBar() {
  // hack for making sure page is scrolled to top when you switch pages
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  setTimeout(() => {
    window.scrollTo(0, 0);
    }, 0.1) // this time out is needed for when using the back button for some reason
  }, [location])

  return (
    <div className="navBarWrapper">
      <HamburgerMenu />
      <img
              className="xomgpopLogo"
        src={xomgpopHeaderLogo}
        alt="xomgpop logo"
      />
    </div>
  );
}

export default NavBar;