import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SquareButton } from "../../common/Buttons/Buttons";
import goodTrainingExamples from "../../../assets/images/good_training_ex.webp";
import badTrainingExamples from "../../../assets/images/bad_training_ex.webp";
import badTrainingExamples2 from "../../../assets/images/bad_training_ex_2.webp";
import "./UploadPage.css";
import { v4 as uuidv4 } from "uuid";
import DropUploader from "./DropUploader/DropUploader";

function UploadPage() {
  const navigate = useNavigate();

  const navToConfirmEmail = () => {
    navigate("/confirm_email");
  };

  const [jobId, setJobId] = useState("");

  const [numPhotosUploaded, setNumPhotosUploaded] = useState(0);

  const handleUploadCountChange = (uploadCount) => {
    setNumPhotosUploaded(uploadCount);
  };

  useEffect(() => {
    if (jobId === "") {
      const jobId = uuidv4();
      localStorage.setItem("jobId", jobId);
      setJobId(jobId);
    }
  }, [jobId]);

  const ctaText =
    numPhotosUploaded === 10
      ? "CONTINUE"
      : `UPLOAD ${10 - numPhotosUploaded} MORE PHOTO${
          10 - numPhotosUploaded > 1 ? "S" : ""
        }`;

  return (
    <div className="uploadPage">
      <h1>Upload Photos</h1>
      <div className="uploadPageContainer">
        <div className="infoColumn">
          <p>
            Select <strong>10 of your best photos.</strong> Please take your
            time and choose carefully. Good uploads will result in amazing
            photos!
          </p>
          <div className="goodExamples">
            <p>
              <strong>REQUIREMENTS</strong>
            </p>
            <p>
              ✅ <strong>Variety:</strong> Different outfits and backgrounds
            </p>
            <p>
              ✅ <strong>Clear:</strong> Face is clearly visible with good
              lighting
            </p>
            <p>
              ✅ <strong>Solo:</strong> You&apos;re alone in the photo
            </p>
            <img alt="good training example" src={goodTrainingExamples} />
          </div>
          <div className="badExamples">
            <p>
              <strong>RESTRICTIONS</strong>
            </p>
            <p>
              ❌ <strong>No Similar Photos:</strong> No photos with the same
              outfit or background
            </p>
            <img alt="bad training example" src={badTrainingExamples} />
            <p>
              ❌ <strong>No Other People:</strong> No photos with other
              people&apos;s faces and hands in them
            </p>
            <p>
              ❌ <strong>No Clutter:</strong> No sunglasses, hats, or photo
              filters that obscure your face or body
            </p>
            <p>
              ❌ <strong>No Goofy Poses:</strong> No unusual facial expressions
              or hand gestures
            </p>
            <img alt="bad training example" src={badTrainingExamples2} />
          </div>
        </div>
        <div className="uploadColumn">
          <DropUploader
            onUploadCountChange={handleUploadCountChange}
            jobId={jobId}
          />
          <SquareButton
            className="stickyUploadButton"
            disabled={numPhotosUploaded !== 10}
            onClick={navToConfirmEmail}
          >
            {ctaText}
          </SquareButton>
          <div className="gradient"></div>
        </div>
      </div>
    </div>
  );
}

export default UploadPage;
