import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/utmHelpers";

import {
  SquareButton,
  SquareButtonTransparent,
} from "../../common/Buttons/Buttons";
import "./LandingPage.css";

import landingStepNumbers from "../../../assets/images/landing_step_numbers.webp";
import landingStep1 from "../../../assets/images/landing_step_1.webp";
import landingStep2 from "../../../assets/images/landing_step_2.webp";
import landingStep3 from "../../../assets/images/landing_step_3.webp";
import landingNumber1 from "../../../assets/images/landing_number_1.webp";
import landingNumber2 from "../../../assets/images/landing_number_2.webp";
import landingNumber3 from "../../../assets/images/landing_number_3.webp";

function LandingPage() {
  const navigate = useNavigate();

  const query = useQuery();

  const navToUploader = () => {
    navigate("/upload");
  };

  useEffect(() => {
    const emailParam = query.get("email");
    if (emailParam) {
      localStorage.setItem("email", emailParam);
    }
  }, [query]);

  return (
    <div>
      <div className="bannerContainer">
        <div className="bannerTextContainer">
          <h1 className="bannerHeader">
            Turn yourself into<br/>a sparkle queen
          </h1>
          <p className="bannerBody">
            Turn your phone pics into personalized photos styled to match XOMG POP! in time for their live tour!
          </p>
          <SquareButtonTransparent onClick={navToUploader}>
            GET STARTED
          </SquareButtonTransparent>
        </div>
      </div>

      <div>
        <h2 className="stepsHeader">3 Easy Steps</h2>
        <img
          src={landingStepNumbers}
          alt="3 Easy Steps"
          className="threeSteps"
        />
      </div>
      <div className="stepImageContainer">
        <img src={landingNumber1} alt="" className="stepNumber" />
        <div className="stepContainer">
          <p>Upload 10 photos</p>
          <img src={landingStep1} alt="Step 1" className="stepImage" />
        </div>
        <img src={landingNumber2} alt="" className="stepNumber" />
        <div className="stepContainer">
          <p>
            We&apos;ll send you back 50 personalized photos for you to choose
            your favorites.
          </p>
          <img
            src={landingStep2}
            alt="Step 2"
            className="stepImage secondImage"
          />
        </div>
        <img src={landingNumber3} alt="" className="stepNumber" />
        <div className="stepContainer">
          <p>Customize your XOMG POP! concert tee</p>
          <img src={landingStep3} alt="Step 3" className="stepImage" />
        </div>
      </div>

      <SquareButton onClick={navToUploader} className="stickyButton">
        GET STARTED
      </SquareButton>
      <div className="gradient"></div>
    </div>
  );
}

export default LandingPage;
